/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
 */

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: #122b08;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1600 900'%3E%3Cpolygon fill='%23767676' points='957 450 539 900 1396 900'/%3E%3Cpolygon fill='%23555555' points='957 450 872.9 900 1396 900'/%3E%3Cpolygon fill='%23808080' points='-60 900 398 662 816 900'/%3E%3Cpolygon fill='%23505050' points='337 900 398 662 816 900'/%3E%3Cpolygon fill='%238a8a8a' points='1203 546 1552 900 876 900'/%3E%3Cpolygon fill='%234b4b4b' points='1203 546 1552 900 1162 900'/%3E%3Cpolygon fill='%23949494' points='641 695 886 900 367 900'/%3E%3Cpolygon fill='%23474747' points='587 900 641 695 886 900'/%3E%3Cpolygon fill='%239e9e9e' points='1710 900 1401 632 1096 900'/%3E%3Cpolygon fill='%23424242' points='1710 900 1401 632 1365 900'/%3E%3Cpolygon fill='%23a8a8a8' points='1210 900 971 687 725 900'/%3E%3Cpolygon fill='%233d3d3d' points='943 900 1210 900 971 687'/%3E%3C/svg%3E");
  background-attachment: fixed;
  background-size: cover;
}

.cursive {
  font-family: "Inconsolata", monospace;
  font-weight: 700;
}

.prose img {
  margin-left: auto;
  margin-right: auto;
  border-radius: 5px;
}

.header {
  background-image: url("./assets/long.jpg");
  background-size: 100%, 100%;
  background-position: center bottom 50%;
  position: sticky;
  border-bottom: 5px solid hsl(0, 9%, 25%);
}

.header-mobile {
  background-image: url("./assets/longMobile.jpg");
  position: sticky;
  border-bottom: 2px solid hsl(0, 9%, 25%);
}

.nav-bar {
  margin: auto;
}
